<template>
  <div class="mt-8 pb-32 mx-auto max-w-2xl">
    <vue-headful
      title="Contacts | Octoa"
    />
    <modal
      name="relatedProjects"
      classes="modal"
      width="100%"
      :max-width="420"
      height="auto"
      :adaptive="true"
    >
      <div class="flex px-2 justify-between">
        <div class="title">
          Contact related projects
        </div>
        <div
          class="close "
          @click="$modal.hide('relatedProjects')"
        />
      </div>

      <div class="flex px-2 -mt-1">
        <div class="w-full">
          {{ contactName }}
          <div class="mt-4">
            <ul
              v-for="(item, index) in relatedProjects"
              :key="index"
            >
              <li
                v-if="relatedProjects.length > 0"
                class="mb-3"
              >
                <router-link
                  :to="`/projects/${item.id}`"
                  class="green underline"
                >
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
            <div v-if="relatedProjects.length == 0">
              <router-link
                :to="/projects/"
                class="green underline"
              >
                Create a new project
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </modal>


    <div class="flex px-4 items-center">
      <div class="w-1/2">
        <h1 class="text-black text-3xl">
          Contacts
        </h1>
      </div>
      <div class="w-1/2 text-right">
        <router-link
          to="/contacts/create"
          class="green-btn"
        >
          Add new contact
        </router-link>
      </div>
    </div>
    <div
      v-show="loading"
      class="mt-32"
    >
      <v-spinner :show="loading" />
    </div>
    <div
      v-show="!loading"
      class="px-4"
    >
      <div
        class="flex flex-wrap pt-10 z-10"
      >
        <div class="w-full md:w-1/2">
          {{ totalContacts }} contacts in total
        </div>

        <div class="w-1/2 text-right">
          <!--  <span @change="refresh">
            <v-checkbox
              id="withProjects"
              v-model="withProjects"
              class="ml-5"
              label="With projects"
            />
          </span>
          <span @change="refresh">
            <v-checkbox
              id="withoutProjects"
              v-model="withoutProjects"
              class="ml-5"
              label="Without projects"
            />
          </span> -->
        </div>
      </div>
      <table-component
        ref="table"
        class="z-20"
        :data="fetchData"
        sort-by="created_at"
        sort-order="desc"
        filter-placeholder="Search for a contact"
        table-class="card"
        filter-no-results="No contacts found."
        @rowClick="handleRowClick"
      >
        <table-column
          :sortable="true"
          show="full_name"
          label="Name"
          cell-class="text-grey-darker"
        />
        <table-column
          :sortable="true"
          show="email"
          label="Email"
          cell-class="text-grey-semi-light"
        />
        <table-column
          :sortable="true"
          show="phone"
          label="Phone number"
          cell-class="text-grey-semi-light"
        />
      </table-component>
    </div>
  </div>
</template>

<script>
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'

let cancelSource

export default {
  data(){
    return {
      loading: true,
      withProjects: 0,
      withoutProjects: 0,
      totalContacts: 0,
      contactName: '',
      relatedProjects: '',
    }
  },
  methods: {
    handleRowClick(payload){
      this.$router.push(`/contacts/${payload.data.id}`)
    },
    async showRelatedProjectsModal(name, id){

      this.contactName = name
      cancelSource = createCancelSource()

      try {
        const { data } = await this.$api.get('contacts').load(id, {
          cancelToken: cancelSource.token
        })

        this.relatedProjects = data.contact.projects
        this.$modal.show('relatedProjects')

      } catch(e){
        logException(e)
      }
    },
    refresh(){
      this.$refs.table.refresh()
    },
    async fetchData({ page, filter, sort }) {

      cancelSource = createCancelSource()
      try {
        const response= await this.$api.get('contacts').list(this.withProjects, this.withoutProjects, filter, sort['fieldName'], sort['order'], {
          cancelToken: cancelSource.token
        })

        this.totalContacts = response.data.length
        this.loading = false
        return response

      } catch(e){
        logException(e)
      }
    }
  }
}
</script>
